import React, { useContext } from 'react';

import * as styles from './PrivacyPolicyPage.module.scss';
import Copyright from '../../common/Copyright';
import { useSticky } from '../../common/NavBar/NavBarProvider';
import SEO from '../../common/SEO';
import Icons from '../../../resources/icons';
import LanguageContext from '../../../contexts/LanguageContext';
import useStaticTranslation from '../../../hooks/useStaticTranslation';
import { useStrapiSingleContent, applyLanguage } from '../../../utils/strapi';

const PrivacyPolicyPage = ({ data }) => {
  const { t } = useStaticTranslation();
  const lng = useContext(LanguageContext);
  const { threshold } = useSticky();

  const content = useStrapiSingleContent(data.staticContent, {
    i18nFields: ['title', 'subtitle', 'intro', 'privacyPolicyText'],
  });

  const { title, subtitle } = content;
  let { intro, privacyPolicyText } = content;

  intro = applyLanguage({
    node: intro,
    lng,
    i18nFields: ['data'],
  });

  privacyPolicyText = applyLanguage({
    node: privacyPolicyText,
    lng,
    i18nFields: ['data'],
  });

  return (
    <>
      <SEO title={t('meta.title')} />
      <div className={styles.container}>
        <div ref={threshold} className={styles.header}>
          <h1
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p className={styles.subtitle}>{subtitle}</p>
          <p className={styles.intro}>{intro.data}</p>
        </div>
        {lng === 'en' && (
          <div className={styles.languageBanner}>
            <Icons.Warning className={styles.warning} />
            {t('content.languageWarning')}
          </div>
        )}
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: privacyPolicyText.data }}
        />
        <Copyright align="center" />
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
